// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Switcher_switcherWrapper__YArSb {
  border-radius: 10px;
  background: #ffffff;
  filter: drop-shadow(0px 4px 10px #ffffff);
  display: flex;
  align-items: center;
  position: relative;
}

.Switcher_item__jT3sC {
  padding: 8px 12px;
  color: #000000;
  z-index: 3;
  cursor: pointer;
  font-family: "Inter-SemiBold";
  font-size: 14px;
  letter-spacing: 0.8px;
}

.Switcher_activeBackground__5FVIr {
  position: absolute;
  top: 0;
  left: 50%;
  border-radius: 10px;
  background: #b7b7b7;
  height: 35px;
  width: 50%;
  z-index: 2;
  transition: all 0.5s;
}

.Switcher_left__jnj7H {
  transform: translateX(-100%);
}

.Switcher_right__peW7G {
  transform: translateX(0%);
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/Switcher/Switcher.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,mBAAA;EACA,yCAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;AACF;;AAEA;EACE,iBAAA;EACA,cAAA;EACA,UAAA;EACA,eAAA;EACA,6BAAA;EACA,eAAA;EACA,qBAAA;AACF;;AAEA;EACE,kBAAA;EACA,MAAA;EACA,SAAA;EACA,mBAAA;EACA,mBAAA;EACA,YAAA;EACA,UAAA;EACA,UAAA;EACA,oBAAA;AACF;;AAEA;EACE,4BAAA;AACF;;AAEA;EACE,yBAAA;AACF","sourcesContent":[".switcherWrapper {\n  border-radius: 10px;\n  background: #ffffff;\n  filter: drop-shadow(0px 4px 10px #ffffff);\n  display: flex;\n  align-items: center;\n  position: relative;\n}\n\n.item {\n  padding: 8px 12px;\n  color: #000000;\n  z-index: 3;\n  cursor: pointer;\n  font-family: \"Inter-SemiBold\";\n  font-size: 14px;\n  letter-spacing: 0.8px;\n}\n\n.activeBackground {\n  position: absolute;\n  top: 0;\n  left: 50%;\n  border-radius: 10px;\n  background: #b7b7b7;\n  height: 35px;\n  width: 50%;\n  z-index: 2;\n  transition: all 0.5s;\n}\n\n.left {\n  transform: translateX(-100%);\n}\n\n.right {\n  transform: translateX(0%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switcherWrapper": `Switcher_switcherWrapper__YArSb`,
	"item": `Switcher_item__jT3sC`,
	"activeBackground": `Switcher_activeBackground__5FVIr`,
	"left": `Switcher_left__jnj7H`,
	"right": `Switcher_right__peW7G`
};
export default ___CSS_LOADER_EXPORT___;
