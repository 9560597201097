// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ManagementSideBar_managementSideBarWrapper__Ahn9s {
  background-color: #fff;
  border-right: 2px solid #FFBBEB;
  display: flex;
  flex-direction: column;
}

.ManagementSideBar_logo__zmx8u {
  margin: 24px;
}

.ManagementSideBar_linksWrapper__EY\\+cc {
  display: flex;
  flex-direction: column;
}

.ManagementSideBar_link__g2t6o {
  font-family: "Inter-SemiBold";
  padding: 12px 24px;
  cursor: pointer;
  transition: all 0.5s;
}
.ManagementSideBar_link__g2t6o:not(.ManagementSideBar_active__jmaTC):hover {
  background-color: #FFBBEB;
}

.ManagementSideBar_active__jmaTC {
  background-color: #FFBBEB;
}`, "",{"version":3,"sources":["webpack://./src/features/ManagementSideBar/ui/ManagementSideBar.module.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,+BAAA;EACA,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,6BAAA;EACA,kBAAA;EACA,eAAA;EACA,oBAAA;AACF;AACE;EACE,yBAAA;AACJ;;AAGA;EACE,yBAAA;AAAF","sourcesContent":[".managementSideBarWrapper {\n  background-color: #fff;\n  border-right: 2px solid #FFBBEB;\n  display: flex;\n  flex-direction: column;\n}\n\n.logo {\n  margin: 24px;\n}\n\n.linksWrapper {\n  display: flex;\n  flex-direction: column;\n}\n\n.link {\n  font-family: \"Inter-SemiBold\";\n  padding: 12px 24px;\n  cursor: pointer;\n  transition: all 0.5s;\n\n  &:not(.active):hover {\n    background-color: #FFBBEB;\n  }\n}\n\n.active {\n  background-color: #FFBBEB;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"managementSideBarWrapper": `ManagementSideBar_managementSideBarWrapper__Ahn9s`,
	"logo": `ManagementSideBar_logo__zmx8u`,
	"linksWrapper": `ManagementSideBar_linksWrapper__EY+cc`,
	"link": `ManagementSideBar_link__g2t6o`,
	"active": `ManagementSideBar_active__jmaTC`
};
export default ___CSS_LOADER_EXPORT___;
