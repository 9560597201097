// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PricesInfo_pricesInfoWrapper__uJXgD {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.PricesInfo_pricesInfoWrapper__uJXgD .PricesInfo_dataWrapper__mFkEU {
  flex: 1 1;
  display: flex;
  align-items: center;
  gap: 12px;
}
.PricesInfo_pricesInfoWrapper__uJXgD .PricesInfo_dataWrapper__mFkEU .PricesInfo_color__l2\\+cH {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}`, "",{"version":3,"sources":["webpack://./src/features/PricesInfo/ui/PricesInfo.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;AACE;EACE,SAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;AACJ;AACI;EACE,WAAA;EACA,YAAA;EACA,mBAAA;AACN","sourcesContent":[".pricesInfoWrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n\n  .dataWrapper {\n    flex: 1;\n    display: flex;\n    align-items: center;\n    gap: 12px;\n\n    .color {\n      width: 30px;\n      height: 30px;\n      border-radius: 100%;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pricesInfoWrapper": `PricesInfo_pricesInfoWrapper__uJXgD`,
	"dataWrapper": `PricesInfo_dataWrapper__mFkEU`,
	"color": `PricesInfo_color__l2+cH`
};
export default ___CSS_LOADER_EXPORT___;
