// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Modal_overlay__XkXR2 {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9998;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Modal_modalWrapper__BgjLb {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 24px;
  background-color: #f8f9f9;
  border-radius: 15px;
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/Modal/Modal.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,8BAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAEA;EACE,uBAAA;EAAA,kBAAA;EACA,wBAAA;EAAA,mBAAA;EACA,aAAA;EACA,yBAAA;EACA,mBAAA;AACF","sourcesContent":[".overlay {\n  width: 100%;\n  height: 100%;\n  background: rgb(0 0 0 / 70%);\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 9998;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.modalWrapper {\n  width: fit-content;\n  height: fit-content;\n  padding: 24px;\n  background-color: #f8f9f9;\n  border-radius: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `Modal_overlay__XkXR2`,
	"modalWrapper": `Modal_modalWrapper__BgjLb`
};
export default ___CSS_LOADER_EXPORT___;
