// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditProduct_modalWrapper__vSxsi {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.EditProduct_modalWrapper__vSxsi .EditProduct_title__eRoaR {
  font-family: "Inter-Bold";
  font-size: 18px;
  letter-spacing: 1.8px;
}
.EditProduct_modalWrapper__vSxsi .EditProduct_dataWrapper__zvhDE {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.EditProduct_modalWrapper__vSxsi .EditProduct_buttonsWrapper__7anJZ {
  display: flex;
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/features/EditProduct/ui/EditProduct.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;AACE;EACE,yBAAA;EACA,eAAA;EACA,qBAAA;AACJ;AAEE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAAJ;AAGE;EACE,aAAA;EACA,yBAAA;AADJ","sourcesContent":[".modalWrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n\n  .title {\n    font-family: \"Inter-Bold\";\n    font-size: 18px;\n    letter-spacing: 1.8px;\n  }\n\n  .dataWrapper {\n    display: flex;\n    flex-direction: column;\n    gap: 24px;\n  }\n\n  .buttonsWrapper {\n    display: flex;\n    justify-content: flex-end;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalWrapper": `EditProduct_modalWrapper__vSxsi`,
	"title": `EditProduct_title__eRoaR`,
	"dataWrapper": `EditProduct_dataWrapper__zvhDE`,
	"buttonsWrapper": `EditProduct_buttonsWrapper__7anJZ`
};
export default ___CSS_LOADER_EXPORT___;
