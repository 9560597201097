// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Input_input__Vnl8Q {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 10px;
  max-width: 200px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #000;
  transition: all 0.5s;
  outline: none;
  max-height: 34px;
}
.Input_input__Vnl8Q::placeholder {
  transition: all 0.5s;
}
.Input_input__Vnl8Q:focus {
  border: 1px solid #cc3292;
  color: #cc3292;
}
.Input_input__Vnl8Q:focus::placeholder {
  color: #cc3292;
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/Input/Input.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,iBAAA;EACA,mBAAA;EACA,SAAA;EACA,gBAAA;EACA,WAAA;EACA,mBAAA;EACA,sBAAA;EACA,oBAAA;EACA,aAAA;EACA,gBAAA;AACF;AAEE;EACE,oBAAA;AAAJ;AAGE;EACE,yBAAA;EACA,cAAA;AADJ;AAGI;EACE,cAAA;AADN","sourcesContent":[".input {\n  display: flex;\n  padding: 8px 16px;\n  align-items: center;\n  gap: 10px;\n  max-width: 200px;\n  width: 100%;\n  border-radius: 10px;\n  border: 1px solid #000;\n  transition: all 0.5s;\n  outline: none;\n  max-height: 34px;\n\n\n  &::placeholder {\n    transition: all 0.5s;\n  }\n\n  &:focus {\n    border: 1px solid #cc3292;\n    color: #cc3292;\n\n    &::placeholder {\n      color: #cc3292;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `Input_input__Vnl8Q`
};
export default ___CSS_LOADER_EXPORT___;
