// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddBasicInfo_addBasicInfoWrapper__oS4IE {
  display: flex;
  gap: 48px;
  justify-content: space-between;
}
.AddBasicInfo_addBasicInfoWrapper__oS4IE .AddBasicInfo_smallDataWrapper__WnVwm {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.AddBasicInfo_addBasicInfoWrapper__oS4IE .AddBasicInfo_specificationsWrapper__m0EzJ {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.AddBasicInfo_addBasicInfoWrapper__oS4IE .AddBasicInfo_specificationsWrapper__m0EzJ .AddBasicInfo_inputsWrapper__E9lbZ {
  display: flex;
  gap: 12px;
}
.AddBasicInfo_addBasicInfoWrapper__oS4IE .AddBasicInfo_specificationsWrapper__m0EzJ .AddBasicInfo_actionButton__pX4WL {
  border: unset;
  background: #b4b4b4;
  color: #fff;
  border-radius: 10px;
  padding: 8px 32px;
  font-family: "Inter-Medium";
  font-size: 14px;
  letter-spacing: 1.8px;
  transition: all 0.5s;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.AddBasicInfo_addBasicInfoWrapper__oS4IE .AddBasicInfo_specificationsWrapper__m0EzJ .AddBasicInfo_actionButton__pX4WL[disabled] {
  background: #e8e8e8;
  color: #fff;
}
.AddBasicInfo_addBasicInfoWrapper__oS4IE .AddBasicInfo_specificationsWrapper__m0EzJ .AddBasicInfo_specificationsPreview__0g80Q {
  display: flex;
  flex-direction: column;
  gap: 8px;
}`, "",{"version":3,"sources":["webpack://./src/features/AddBasicInfo/ui/AddBasicInfo.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,8BAAA;AACF;AACE;EACE,SAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;AAEE;EACE,SAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AAAJ;AAEI;EACE,aAAA;EACA,SAAA;AAAN;AAGI;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,mBAAA;EACA,iBAAA;EACA,2BAAA;EACA,eAAA;EACA,qBAAA;EACA,oBAAA;EACA,yBAAA;EACA,iBAAA;EACA,eAAA;AADN;AAGM;EACE,mBAAA;EACA,WAAA;AADR;AAKI;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AAHN","sourcesContent":[".addBasicInfoWrapper {\n  display: flex;\n  gap: 48px;\n  justify-content: space-between;\n\n  .smallDataWrapper {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    gap: 12px;\n  }\n\n  .specificationsWrapper {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    gap: 12px;\n\n    .inputsWrapper {\n      display: flex;\n      gap: 12px;\n    }\n\n    .actionButton {\n      border: unset;\n      background: #b4b4b4;\n      color: #fff;\n      border-radius: 10px;\n      padding: 8px 32px;\n      font-family: \"Inter-Medium\";\n      font-size: 14px;\n      letter-spacing: 1.8px;\n      transition: all 0.5s;\n      -webkit-user-select: none;\n      user-select: none;\n      cursor: pointer;\n\n      &[disabled] {\n        background: #e8e8e8;\n        color: #fff;\n      }\n    }\n\n    .specificationsPreview {\n      display: flex;\n      flex-direction: column;\n      gap: 8px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addBasicInfoWrapper": `AddBasicInfo_addBasicInfoWrapper__oS4IE`,
	"smallDataWrapper": `AddBasicInfo_smallDataWrapper__WnVwm`,
	"specificationsWrapper": `AddBasicInfo_specificationsWrapper__m0EzJ`,
	"inputsWrapper": `AddBasicInfo_inputsWrapper__E9lbZ`,
	"actionButton": `AddBasicInfo_actionButton__pX4WL`,
	"specificationsPreview": `AddBasicInfo_specificationsPreview__0g80Q`
};
export default ___CSS_LOADER_EXPORT___;
