// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_headerWrapper__8tt6Z {
  margin-top: 24px;
  padding: 0 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (min-width: 1316px) {
  .Header_headerWrapper__8tt6Z {
    max-width: 1260px;
    margin: 24px auto 0;
    padding: 0;
  }
}
@media screen and (max-width: 800px) {
  .Header_headerWrapper__8tt6Z {
    display: none;
  }
}

.Header_itemsWrapper__9AgXL {
  display: flex;
  align-items: center;
  gap: 24px;
}
.Header_itemsWrapper__9AgXL a {
  font-family: "Inter-SemiBold";
  font-size: 16px;
  letter-spacing: 0.36px;
  color: #8f8f8f;
  text-decoration: none;
}

.Header_activeLink__ZbuGG {
  color: #cc3292 !important;
}

.Header_catalogWrapper__ypO0o {
  position: relative;
}

.Header_catalogCount__eOoTp {
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 4px 8px;
  border-radius: 10px;
  background: #cc3292;
  color: #fff;
  font-family: "Inter-SemiBold";
  font-size: 10px;
  letter-spacing: 0.5px;
}`, "",{"version":3,"sources":["webpack://./src/features/Header/ui/Header.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,eAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AACF;AACE;EAPF;IAQI,iBAAA;IACA,mBAAA;IACA,UAAA;EAEF;AACF;AAAE;EAbF;IAcI,aAAA;EAGF;AACF;;AAAA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAGF;AADE;EACE,6BAAA;EACA,eAAA;EACA,sBAAA;EACA,cAAA;EACA,qBAAA;AAGJ;;AACA;EACE,yBAAA;AAEF;;AACA;EACE,kBAAA;AAEF;;AACA;EACE,kBAAA;EACA,UAAA;EACA,YAAA;EACA,gBAAA;EACA,mBAAA;EACA,mBAAA;EACA,WAAA;EACA,6BAAA;EACA,eAAA;EACA,qBAAA;AAEF","sourcesContent":[".headerWrapper {\n  margin-top: 24px;\n  padding: 0 26px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n\n  @media screen and (min-width: 1316px) {\n    max-width: 1260px;\n    margin: 24px auto 0;\n    padding: 0;\n  }\n\n  @media screen and (max-width: 800px) {\n    display: none;\n  }\n}\n\n.itemsWrapper {\n  display: flex;\n  align-items: center;\n  gap: 24px;\n\n  a {\n    font-family: \"Inter-SemiBold\";\n    font-size: 16px;\n    letter-spacing: 0.36px;\n    color: #8f8f8f;\n    text-decoration: none;\n  }\n}\n\n.activeLink {\n  color: #cc3292 !important;\n}\n\n.catalogWrapper {\n  position: relative;\n}\n\n.catalogCount {\n  position: absolute;\n  top: -10px;\n  right: -10px;\n  padding: 4px 8px;\n  border-radius: 10px;\n  background: #cc3292;\n  color: #fff;\n  font-family: \"Inter-SemiBold\";\n  font-size: 10px;\n  letter-spacing: 0.5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerWrapper": `Header_headerWrapper__8tt6Z`,
	"itemsWrapper": `Header_itemsWrapper__9AgXL`,
	"activeLink": `Header_activeLink__ZbuGG`,
	"catalogWrapper": `Header_catalogWrapper__ypO0o`,
	"catalogCount": `Header_catalogCount__eOoTp`
};
export default ___CSS_LOADER_EXPORT___;
