// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ColorInfo_colorInfo__YFKlJ {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.ColorInfo_colorPicker__Gh-ji {
  display: flex;
  align-items: center;
  gap: 10px;
}
.ColorInfo_colorPicker__Gh-ji label {
  font-size: 16px;
}

.ColorInfo_colorInput__w-LXd {
  width: 100px;
  height: 40px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.ColorInfo_dragDropArea__avRF1 {
  border: 2px dashed #d9d9d9;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  height: 150px;
  background-color: #fafafa;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.ColorInfo_dragDropArea__avRF1:hover {
  border-color: #cc3292;
}

.ColorInfo_fileInput__FMqIS {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.ColorInfo_fileList__dag5s {
  list-style: none;
  padding: 0;
  margin: 0;
}
.ColorInfo_fileList__dag5s li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  margin: 10px 0;
}

.ColorInfo_deleteButton__scFVY {
  background-color: #cc3292;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}
.ColorInfo_deleteButton__scFVY:hover {
  background-color: #a32875;
}`, "",{"version":3,"sources":["webpack://./src/features/ColorInfo/ui/ColorInfo.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,aAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AACF;AACE;EACE,eAAA;AACJ;;AAGA;EACE,YAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;AAAF;;AAGA;EACE,0BAAA;EACA,kBAAA;EACA,aAAA;EACA,kBAAA;EACA,aAAA;EACA,yBAAA;EACA,eAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;AAAF;AAEE;EACE,qBAAA;AAAJ;;AAIA;EACE,UAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;AADF;;AAIA;EACE,gBAAA;EACA,UAAA;EACA,SAAA;AADF;AAGE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;EACA,cAAA;AADJ;;AAKA;EACE,yBAAA;EACA,YAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,eAAA;AAFF;AAIE;EACE,yBAAA;AAFJ","sourcesContent":[".colorInfo {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  padding: 20px;\n}\n\n.colorPicker {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n\n  label {\n    font-size: 16px;\n  }\n}\n\n.colorInput {\n  width: 100px;\n  height: 40px;\n  border: 1px solid #d9d9d9;\n  border-radius: 4px;\n}\n\n.dragDropArea {\n  border: 2px dashed #d9d9d9;\n  border-radius: 4px;\n  padding: 20px;\n  text-align: center;\n  height: 150px;\n  background-color: #fafafa;\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n\n  &:hover {\n    border-color: #cc3292;\n  }\n}\n\n.fileInput {\n  opacity: 0;\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  cursor: pointer;\n}\n\n.fileList {\n  list-style: none;\n  padding: 0;\n  margin: 0;\n\n  li {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    font-size: 14px;\n    margin: 10px 0;\n  }\n}\n\n.deleteButton {\n  background-color: #cc3292;\n  color: white;\n  border: none;\n  padding: 5px 10px;\n  border-radius: 4px;\n  cursor: pointer;\n\n  &:hover {\n    background-color: darken(#cc3292, 10%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colorInfo": `ColorInfo_colorInfo__YFKlJ`,
	"colorPicker": `ColorInfo_colorPicker__Gh-ji`,
	"colorInput": `ColorInfo_colorInput__w-LXd`,
	"dragDropArea": `ColorInfo_dragDropArea__avRF1`,
	"fileInput": `ColorInfo_fileInput__FMqIS`,
	"fileList": `ColorInfo_fileList__dag5s`,
	"deleteButton": `ColorInfo_deleteButton__scFVY`
};
export default ___CSS_LOADER_EXPORT___;
