// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ManagementSEO_managementSEOWrapper__kLdLP {
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 242, 251, 0.4431372549);
  padding: 100px;
}
.ManagementSEO_managementSEOWrapper__kLdLP .ManagementSEO_contentWrapper__5aL\\+6 {
  border: 2px solid #ffbbeb;
  border-radius: 15px;
  background-color: #fff;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: calc(100vw - 450px);
}
.ManagementSEO_managementSEOWrapper__kLdLP .ManagementSEO_contentWrapper__5aL\\+6 .ManagementSEO_headerWrapper__aQDtL {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ManagementSEO_managementSEOWrapper__kLdLP .ManagementSEO_contentWrapper__5aL\\+6 .ManagementSEO_headerWrapper__aQDtL .ManagementSEO_title__bizfM {
  font-family: "Inter-Bold";
  font-size: 18px;
  letter-spacing: 1.8px;
}
.ManagementSEO_managementSEOWrapper__kLdLP .ManagementSEO_contentWrapper__5aL\\+6 .ManagementSEO_itemsWrapper__0wo5g {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  overflow-y: auto;
}
.ManagementSEO_managementSEOWrapper__kLdLP .ManagementSEO_contentWrapper__5aL\\+6 .ManagementSEO_itemsWrapper__0wo5g .ManagementSEO_itemWrapper__6dB95 {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 24px;
  background: #fff2fb;
  border-radius: 15px;
  width: -moz-fit-content;
  width: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/widgets/ManagementSEO/ui/ManagementSEO.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,mDAAA;EACA,cAAA;AACF;AACE;EACE,yBAAA;EACA,mBAAA;EACA,sBAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,0BAAA;AACJ;AACI;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AACN;AACM;EACE,yBAAA;EACA,eAAA;EACA,qBAAA;AACR;AAGI;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,eAAA;EACA,gBAAA;AADN;AAGM;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EAAA,kBAAA;AADR","sourcesContent":[".managementSEOWrapper {\n  width: 100vw;\n  height: 100vh;\n  background-color: #fff2fb71;\n  padding: 100px;\n\n  .contentWrapper {\n    border: 2px solid #ffbbeb;\n    border-radius: 15px;\n    background-color: #fff;\n    height: 100%;\n    padding: 24px;\n    display: flex;\n    flex-direction: column;\n    gap: 24px;\n    width: calc(100vw - 450px);\n\n    .headerWrapper {\n      display: flex;\n      justify-content: space-between;\n      align-items: center;\n\n      .title {\n        font-family: \"Inter-Bold\";\n        font-size: 18px;\n        letter-spacing: 1.8px;\n      }\n    }\n\n    .itemsWrapper {\n      display: flex;\n      align-items: center;\n      gap: 16px;\n      flex-wrap: wrap;\n      overflow-y: auto;\n\n      .itemWrapper {\n        display: flex;\n        align-items: center;\n        gap: 24px;\n        padding: 24px;\n        background: #fff2fb;\n        border-radius: 15px;\n        width: fit-content;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"managementSEOWrapper": `ManagementSEO_managementSEOWrapper__kLdLP`,
	"contentWrapper": `ManagementSEO_contentWrapper__5aL+6`,
	"headerWrapper": `ManagementSEO_headerWrapper__aQDtL`,
	"title": `ManagementSEO_title__bizfM`,
	"itemsWrapper": `ManagementSEO_itemsWrapper__0wo5g`,
	"itemWrapper": `ManagementSEO_itemWrapper__6dB95`
};
export default ___CSS_LOADER_EXPORT___;
