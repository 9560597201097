// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Companies_companiesWrapper__DGcZw {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 800px) {
  .Companies_companiesWrapper__DGcZw {
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .Companies_companiesWrapper__DGcZw::-webkit-scrollbar {
    display: none;
  }
}
.Companies_companiesWrapper__DGcZw img {
  opacity: 0.5;
  transition: all 0.5s;
  cursor: pointer;
  height: 30px;
  width: auto;
}
.Companies_companiesWrapper__DGcZw img:hover {
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/entities/Companies/ui/Companies.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;AACF;AACE;EAJF;IAKI,gBAAA;IACA,wBAAA;IACA,qBAAA;EAEF;EAAE;IACE,aAAA;EAEJ;AACF;AACE;EACE,YAAA;EACA,oBAAA;EACA,eAAA;EACA,YAAA;EACA,WAAA;AACJ;AACI;EACE,UAAA;AACN","sourcesContent":[".companiesWrapper {\n  display: flex;\n  justify-content: space-between;\n\n  @media screen and (max-width: 800px) {\n    overflow-x: auto;\n    -ms-overflow-style: none;\n    scrollbar-width: none;\n    \n    &::-webkit-scrollbar {\n      display: none;\n    }\n  }\n\n  img {\n    opacity: 0.5;\n    transition: all 0.5s;\n    cursor: pointer;\n    height: 30px;\n    width: auto;\n\n    &:hover {\n      opacity: 1;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"companiesWrapper": `Companies_companiesWrapper__DGcZw`
};
export default ___CSS_LOADER_EXPORT___;
