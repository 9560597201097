// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Catalog_catalogWrapper__I3yNB {
  margin: 48px 0;
  padding: 0 26px;
  display: flex;
  flex-direction: column;
  gap: 48px;
  min-height: calc(100vh - 419px);
}
@media screen and (min-width: 1316px) {
  .Catalog_catalogWrapper__I3yNB {
    max-width: 1260px;
    margin: 48px auto;
    padding: 0;
  }
}

.Catalog_title__wptCM {
  font-family: "Inter-Medium";
  font-size: 18px;
  letter-spacing: 1.8px;
}

.Catalog_dataWrapper__ZuBT8 {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.Catalog_item__lnQh4 {
  background-color: #fff;
  border: 2px solid #ffbbeb;
  border-radius: 10px;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Catalog_item__lnQh4 a {
  color: #000;
}

.Catalog_quantityWrapper__6egaD {
  display: flex;
  align-items: center;
  gap: 12px;
}

.Catalog_quantityAction__MJZlG {
  font-family: "Inter-SemiBold";
  cursor: pointer;
}

.Catalog_quantity__6FfqG {
  font-family: "Inter-SemiBold";
}

.Catalog_colors__wyslL {
  display: flex;
  margin-top: 5px;
}

.Catalog_color__ek\\+\\+z {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  border: 1px solid #ccc;
  border-radius: 50%;
}

.Catalog_remove__F2cuy {
  font-family: "Inter-SemiBold";
  cursor: pointer;
  font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Catalog/ui/Catalog.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,eAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,+BAAA;AACF;AACE;EARF;IASI,iBAAA;IACA,iBAAA;IACA,UAAA;EAEF;AACF;;AACA;EACE,2BAAA;EACA,eAAA;EACA,qBAAA;AAEF;;AACA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAEF;;AACA;EACE,sBAAA;EACA,yBAAA;EACA,mBAAA;EACA,aAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AAEF;AAAE;EACE,WAAA;AAEJ;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AACF;;AAEA;EACE,6BAAA;EACA,eAAA;AACF;;AAEA;EACE,6BAAA;AACF;;AAEA;EACE,aAAA;EACA,eAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,iBAAA;EACA,sBAAA;EACA,kBAAA;AACF;;AAEA;EACE,6BAAA;EACA,eAAA;EACA,eAAA;AACF","sourcesContent":[".catalogWrapper {\n  margin: 48px 0;\n  padding: 0 26px;\n  display: flex;\n  flex-direction: column;\n  gap: 48px;\n  min-height: calc(100vh - 419px);\n\n  @media screen and (min-width: 1316px) {\n    max-width: 1260px;\n    margin: 48px auto;\n    padding: 0;\n  }\n}\n\n.title {\n  font-family: \"Inter-Medium\";\n  font-size: 18px;\n  letter-spacing: 1.8px;\n}\n\n.dataWrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n\n.item {\n  background-color: #fff;\n  border: 2px solid #ffbbeb;\n  border-radius: 10px;\n  padding: 24px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n\n  a {\n    color: #000;\n  }\n}\n\n.quantityWrapper {\n  display: flex;\n  align-items: center;\n  gap: 12px;\n}\n\n.quantityAction {\n  font-family: \"Inter-SemiBold\";\n  cursor: pointer;\n}\n\n.quantity {\n  font-family: \"Inter-SemiBold\";\n}\n\n.colors {\n  display: flex;\n  margin-top: 5px;\n}\n\n.color {\n  width: 20px;\n  height: 20px;\n  margin-right: 5px;\n  border: 1px solid #ccc;\n  border-radius: 50%;\n}\n\n.remove {\n  font-family: \"Inter-SemiBold\";\n  cursor: pointer;\n  font-size: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"catalogWrapper": `Catalog_catalogWrapper__I3yNB`,
	"title": `Catalog_title__wptCM`,
	"dataWrapper": `Catalog_dataWrapper__ZuBT8`,
	"item": `Catalog_item__lnQh4`,
	"quantityWrapper": `Catalog_quantityWrapper__6egaD`,
	"quantityAction": `Catalog_quantityAction__MJZlG`,
	"quantity": `Catalog_quantity__6FfqG`,
	"colors": `Catalog_colors__wyslL`,
	"color": `Catalog_color__ek++z`,
	"remove": `Catalog_remove__F2cuy`
};
export default ___CSS_LOADER_EXPORT___;
