// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DiscountModal_modalWrapper__3Vn\\+Z {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.DiscountModal_title__pyHNk {
  font-family: "Inter-Bold";
  font-size: 18px;
  letter-spacing: 1.8px;
}

.DiscountModal_buttonsWrapper__fo0oV {
  display: flex;
  justify-content: space-between;
  gap: 24px;
}

.DiscountModal_colorsWrapper__Yh5d4 {
  display: flex;
  gap: 8px;
}

.DiscountModal_color__xHm8C {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
}

.DiscountModal_activeColor__rdWN9 {
  border: 2px solid #ffbbeb;
}`, "",{"version":3,"sources":["webpack://./src/features/DiscountModal/ui/DiscountModal.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,yBAAA;EACA,eAAA;EACA,qBAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,QAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,iBAAA;EACA,sBAAA;EACA,kBAAA;EACA,eAAA;AACF;;AAEA;EACE,yBAAA;AACF","sourcesContent":[".modalWrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.title {\n  font-family: \"Inter-Bold\";\n  font-size: 18px;\n  letter-spacing: 1.8px;\n}\n\n.buttonsWrapper {\n  display: flex;\n  justify-content: space-between;\n  gap: 24px;\n}\n\n.colorsWrapper {\n  display: flex;\n  gap: 8px;\n}\n\n.color {\n  width: 20px;\n  height: 20px;\n  margin-right: 5px;\n  border: 1px solid #ccc;\n  border-radius: 50%;\n  cursor: pointer;\n}\n\n.activeColor {\n  border: 2px solid #ffbbeb;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalWrapper": `DiscountModal_modalWrapper__3Vn+Z`,
	"title": `DiscountModal_title__pyHNk`,
	"buttonsWrapper": `DiscountModal_buttonsWrapper__fo0oV`,
	"colorsWrapper": `DiscountModal_colorsWrapper__Yh5d4`,
	"color": `DiscountModal_color__xHm8C`,
	"activeColor": `DiscountModal_activeColor__rdWN9`
};
export default ___CSS_LOADER_EXPORT___;
