// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Filter_filtersWrapper__eTefO {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background-color: #e8e8e8;
  border-radius: 10px;
  max-height: 95vh;
  height: 100%;
  overflow: scroll;
  min-width: 216px;
  position: sticky;
  top: 20px;
}

.Filter_title__NlyyV {
  font-family: "Inter-SemiBold";
  letter-spacing: 1.8px;
}

.Filter_companiesWrapper__JTymP {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.Filter_company__bBCVm {
  display: flex;
  gap: 8px;
  cursor: pointer;
}

.Filter_priceInputWrapper__B-qSn {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.Filter_priceInputWrapper__B-qSn p {
  font-family: "Inter-Medium";
  font-size: 14px;
  letter-spacing: 0.8px;
}

@media screen and (max-width: 800px) {
  .Filter_filtersWrapper__eTefO {
    position: relative;
  }
}`, "",{"version":3,"sources":["webpack://./src/entities/Filter/ui/Filter.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,gBAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,gBAAA;EACA,SAAA;AACF;;AAEA;EACE,6BAAA;EACA,qBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AACF;;AAEA;EACE,aAAA;EACA,QAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AACF;AACE;EACE,2BAAA;EACA,eAAA;EACA,qBAAA;AACJ;;AAGA;EACA;IACE,kBAAA;EAAA;AACF","sourcesContent":[".filtersWrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  padding: 16px;\n  background-color: #e8e8e8;\n  border-radius: 10px;\n  max-height: 95vh;\n  height: 100%;\n  overflow: scroll;\n  min-width: 216px;\n  position: sticky;\n  top: 20px;\n}\n\n.title {\n  font-family: \"Inter-SemiBold\";\n  letter-spacing: 1.8px;\n}\n\n.companiesWrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.company {\n  display: flex;\n  gap: 8px;\n  cursor: pointer;\n}\n\n.priceInputWrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n\n  p {\n    font-family: \"Inter-Medium\";\n    font-size: 14px;\n    letter-spacing: 0.8px;\n  }\n}\n\n@media screen and (max-width: 800px){\n.filtersWrapper {\n  position: relative;\n}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filtersWrapper": `Filter_filtersWrapper__eTefO`,
	"title": `Filter_title__NlyyV`,
	"companiesWrapper": `Filter_companiesWrapper__JTymP`,
	"company": `Filter_company__bBCVm`,
	"priceInputWrapper": `Filter_priceInputWrapper__B-qSn`
};
export default ___CSS_LOADER_EXPORT___;
