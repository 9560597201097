// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactForum_contactForumWrapper__o22E5 {
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 100%;
  flex: 1 1;
  max-width: 40vw;
}
@media screen and (max-width: 800px) {
  .ContactForum_contactForumWrapper__o22E5 {
    max-width: unset;
  }
}

.ContactForum_title__flpid {
  font-family: "Inter-Bold";
  font-size: 18px;
  letter-spacing: 1.8px;
}

.ContactForum_inputsWrapper__Umx9B {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.ContactForum_inputsRow__9oKlJ {
  display: flex;
  gap: 24px;
}
@media screen and (max-width: 800px) {
  .ContactForum_inputsRow__9oKlJ {
    flex-direction: column;
  }
}`, "",{"version":3,"sources":["webpack://./src/features/ContactForum/ui/ContactForum.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,WAAA;EACA,SAAA;EACA,eAAA;AACF;AACE;EARF;IASI,gBAAA;EAEF;AACF;;AACA;EACE,yBAAA;EACA,eAAA;EACA,qBAAA;AAEF;;AACA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAEF;;AACA;EACE,aAAA;EACA,SAAA;AAEF;AAAE;EAJF;IAKI,sBAAA;EAGF;AACF","sourcesContent":[".contactForumWrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 48px;\n  width: 100%;\n  flex: 1;\n  max-width: 40vw;\n  \n  @media screen and (max-width: 800px) {\n    max-width: unset;\n  }\n}\n\n.title {\n  font-family: \"Inter-Bold\";\n  font-size: 18px;\n  letter-spacing: 1.8px;\n}\n\n.inputsWrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.inputsRow {\n  display: flex;\n  gap: 24px;\n\n  @media screen and (max-width: 800px) {\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contactForumWrapper": `ContactForum_contactForumWrapper__o22E5`,
	"title": `ContactForum_title__flpid`,
	"inputsWrapper": `ContactForum_inputsWrapper__Umx9B`,
	"inputsRow": `ContactForum_inputsRow__9oKlJ`
};
export default ___CSS_LOADER_EXPORT___;
