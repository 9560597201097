// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ManagementCategories_managementCategoriesWrapper__5l7tK {
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 242, 251, 0.4431372549);
  padding: 100px;
}

.ManagementCategories_contentWrapper__NsyVX {
  border: 2px solid #ffbbeb;
  border-radius: 15px;
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.ManagementCategories_headerWrapper__cQ4Sm {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ManagementCategories_title__SU8Wx {
  font-family: "Inter-Bold";
  font-size: 18px;
  letter-spacing: 1.8px;
}

.ManagementCategories_addButton__9mAIN {
  border: 2px solid #ffbbeb;
  border-radius: 15px;
  padding: 8px 12px;
  background: #ffbbeb;
  color: #000;
  cursor: pointer;
  transition: all 0.5s;
  font-family: "Inter-Bold";
  letter-spacing: 1.8px;
}
.ManagementCategories_addButton__9mAIN:hover {
  border: 2px solid #ffbbeb;
  background-color: #fff;
  color: #000;
}

.ManagementCategories_categoriesWrapper__DZwhh {
  display: grid;
  grid-template-columns: repeat(1, minmax(300px, 1fr));
  gap: 24px;
  overflow-y: auto;
  padding: 10px;
}

.ManagementCategories_addWrapper__dtJOM {
  width: 100%;
  height: 116px;
  border: 1px solid #ffbbeb;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/widgets/ManagementCategories/ui/ManagementCategories.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,mDAAA;EACA,cAAA;AACF;;AAEA;EACE,yBAAA;EACA,mBAAA;EACA,sBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AACF;;AAEA;EACE,yBAAA;EACA,eAAA;EACA,qBAAA;AACF;;AAEA;EACE,yBAAA;EACA,mBAAA;EACA,iBAAA;EACA,mBAAA;EACA,WAAA;EACA,eAAA;EACA,oBAAA;EACA,yBAAA;EACA,qBAAA;AACF;AACE;EACE,yBAAA;EACA,sBAAA;EACA,WAAA;AACJ;;AAGA;EACE,aAAA;EACA,oDAAA;EACA,SAAA;EACA,gBAAA;EACA,aAAA;AAAF;;AAGA;EACE,WAAA;EACA,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;AAAF","sourcesContent":[".managementCategoriesWrapper {\n  width: 100vw;\n  height: 100vh;\n  background-color: #fff2fb71;\n  padding: 100px;\n}\n\n.contentWrapper {\n  border: 2px solid #ffbbeb;\n  border-radius: 15px;\n  background-color: #fff;\n  width: 100%;\n  height: 100%;\n  padding: 24px;\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.headerWrapper {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.title {\n  font-family: \"Inter-Bold\";\n  font-size: 18px;\n  letter-spacing: 1.8px;\n}\n\n.addButton {\n  border: 2px solid #ffbbeb;\n  border-radius: 15px;\n  padding: 8px 12px;\n  background: #ffbbeb;\n  color: #000;\n  cursor: pointer;\n  transition: all 0.5s;\n  font-family: \"Inter-Bold\";\n  letter-spacing: 1.8px;\n\n  &:hover {\n    border: 2px solid #ffbbeb;\n    background-color: #fff;\n    color: #000;\n  }\n}\n\n.categoriesWrapper {\n  display: grid;\n  grid-template-columns: repeat(1, minmax(300px, 1fr));\n  gap: 24px;\n  overflow-y: auto;\n  padding: 10px;\n}\n\n.addWrapper {\n  width: 100%;\n  height: 116px;\n  border: 1px solid #ffbbeb;\n  border-radius: 15px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"managementCategoriesWrapper": `ManagementCategories_managementCategoriesWrapper__5l7tK`,
	"contentWrapper": `ManagementCategories_contentWrapper__NsyVX`,
	"headerWrapper": `ManagementCategories_headerWrapper__cQ4Sm`,
	"title": `ManagementCategories_title__SU8Wx`,
	"addButton": `ManagementCategories_addButton__9mAIN`,
	"categoriesWrapper": `ManagementCategories_categoriesWrapper__DZwhh`,
	"addWrapper": `ManagementCategories_addWrapper__dtJOM`
};
export default ___CSS_LOADER_EXPORT___;
