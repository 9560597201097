// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CategoryInfo_infoWrapper__CVI2Q {
  position: relative;
  margin-bottom: 20px;
}
.CategoryInfo_infoWrapper__CVI2Q .CategoryInfo_info__-UXLi {
  overflow: hidden;
  position: relative;
  background: #e8e8e8;
  margin-bottom: 10px;
  padding: 24px 24px 40px;
  border-radius: 15px;
  height: auto;
  max-height: 10em;
}
.CategoryInfo_infoWrapper__CVI2Q .CategoryInfo_info__-UXLi.CategoryInfo_isExpanded__IuS0l {
  max-height: 140em;
}
.CategoryInfo_infoWrapper__CVI2Q .CategoryInfo_info__-UXLi.CategoryInfo_isExpanded__IuS0l::after {
  background: unset;
}
.CategoryInfo_infoWrapper__CVI2Q .CategoryInfo_info__-UXLi::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60%;
  background: linear-gradient(0deg, rgb(232, 232, 232) 0%, rgb(232, 232, 232) 60%, rgba(115, 115, 115, 0) 100%);
  border-radius: 15px;
  z-index: 1;
}
.CategoryInfo_infoWrapper__CVI2Q .CategoryInfo_infoButton__QHo3L {
  position: absolute;
  bottom: 24px;
  right: 24px;
  color: #cc3292;
  border: none;
  cursor: pointer;
  z-index: 2;
  background: transparent;
}`, "",{"version":3,"sources":["webpack://./src/entities/CategoryInfo/ui/CategoryInfo.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mBAAA;AACF;AACE;EACE,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,gBAAA;AACJ;AACI;EACE,iBAAA;AACN;AACM;EACE,iBAAA;AACR;AAGI;EACE,WAAA;EACA,kBAAA;EACA,OAAA;EACA,SAAA;EACA,WAAA;EACA,WAAA;EACA,6GAAA;EAMA,mBAAA;EACA,UAAA;AANN;AAUE;EACE,kBAAA;EACA,YAAA;EACA,WAAA;EACA,cAAA;EACA,YAAA;EACA,eAAA;EACA,UAAA;EACA,uBAAA;AARJ","sourcesContent":[".infoWrapper {\n  position: relative;\n  margin-bottom: 20px;\n\n  .info {\n    overflow: hidden;\n    position: relative;\n    background: #e8e8e8;\n    margin-bottom: 10px;\n    padding: 24px 24px 40px;\n    border-radius: 15px;\n    height: auto;\n    max-height: 10em;\n\n    &.isExpanded {\n      max-height: 140em;\n\n      &::after {\n        background: unset;\n      }\n    }\n\n    &::after {\n      content: \"\";\n      position: absolute;\n      left: 0;\n      bottom: 0;\n      width: 100%;\n      height: 60%;\n      background: linear-gradient(\n        0deg,\n        rgba(232, 232, 232, 1) 0%,\n        rgba(232, 232, 232, 1) 60%,\n        rgba(115, 115, 115, 0) 100%\n      );\n      border-radius: 15px;\n      z-index: 1;\n    }\n  }\n\n  .infoButton {\n    position: absolute;\n    bottom: 24px;\n    right: 24px;\n    color: #cc3292;\n    border: none;\n    cursor: pointer;\n    z-index: 2;\n    background: transparent;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoWrapper": `CategoryInfo_infoWrapper__CVI2Q`,
	"info": `CategoryInfo_info__-UXLi`,
	"isExpanded": `CategoryInfo_isExpanded__IuS0l`,
	"infoButton": `CategoryInfo_infoButton__QHo3L`
};
export default ___CSS_LOADER_EXPORT___;
