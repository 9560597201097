// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ShowConnections_showConnectionsWrapper__ILBYa {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 800px) {
  .ShowConnections_showConnectionsWrapper__ILBYa {
    flex-direction: column-reverse;
    gap: 48px;
  }
}`, "",{"version":3,"sources":["webpack://./src/widgets/ShowConnections/ui/ShowConnections.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;AACF;AACE;EAJF;IAKI,8BAAA;IACA,SAAA;EAEF;AACF","sourcesContent":[".showConnectionsWrapper {\n  display: flex;\n  justify-content: space-between;\n  \n  @media screen and (max-width: 800px) {\n    flex-direction: column-reverse;\n    gap: 48px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"showConnectionsWrapper": `ShowConnections_showConnectionsWrapper__ILBYa`
};
export default ___CSS_LOADER_EXPORT___;
