// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoadingSpinner_loadingSpinnerWrapper__MXmv9 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LoadingSpinner_loadingSpinner__hqQnS {
  position: relative;
  animation: LoadingSpinner_flix__LscrZ 3s ease-in infinite alternate;
}
.LoadingSpinner_loadingSpinner__hqQnS:before {
  content: "";
  display: block;
  font-size: 0;
  width: 48px;
  height: 48px;
  background-color: #cc3292;
  background-image: radial-gradient(circle 12px at 22px 22px, #fff 100%, transparent 0), radial-gradient(circle 10px at 6px 40px, #fff 100%, transparent 0), radial-gradient(circle 14px at 31px -6px, #fff 100%, transparent 0), radial-gradient(circle 5px at 40px 30px, #fff 100%, transparent 0);
  border-radius: 50%;
  animation: LoadingSpinner_rotate__FORGL 1s linear infinite;
}
.LoadingSpinner_loadingSpinner__hqQnS:after {
  content: "";
  position: absolute;
  top: 0%;
  transform: translate(-50%, -100%);
  left: 50%;
  width: 24px;
  height: 12px;
  background: #cc3292;
  border-radius: 50px 50px 0 0;
}

@keyframes LoadingSpinner_flix__LscrZ {
  0%, 60% {
    transform: rotate(-10deg);
  }
  100%, 30%, 80% {
    transform: rotate(5deg);
  }
}
@keyframes LoadingSpinner_rotate__FORGL {
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/LoadingSpinner/LoadingSpinner.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE,kBAAA;EACA,mEAAA;AACF;AACE;EACE,WAAA;EACA,cAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,yBAAA;EACA,kSAAA;EAIA,kBAAA;EACA,0DAAA;AAFJ;AAKE;EACE,WAAA;EACA,kBAAA;EACA,OAAA;EACA,iCAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,4BAAA;AAHJ;;AAOA;EACE;IAEE,yBAAA;EALF;EAOA;IAGE,uBAAA;EAPF;AACF;AASA;EACE;IACE,yBAAA;EAPF;AACF","sourcesContent":[".loadingSpinnerWrapper {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.loadingSpinner {\n  position: relative;\n  animation: flix 3s ease-in infinite alternate;\n\n  &:before {\n    content: \"\";\n    display: block;\n    font-size: 0;\n    width: 48px;\n    height: 48px;\n    background-color: #cc3292;\n    background-image: radial-gradient(circle 12px at 22px 22px, #fff 100%, transparent 0),\n      radial-gradient(circle 10px at 6px 40px, #fff 100%, transparent 0),\n      radial-gradient(circle 14px at 31px -6px, #fff 100%, transparent 0),\n      radial-gradient(circle 5px at 40px 30px, #fff 100%, transparent 0);\n    border-radius: 50%;\n    animation: rotate 1s linear infinite;\n  }\n\n  &:after {\n    content: \"\";\n    position: absolute;\n    top: 0%;\n    transform: translate(-50%, -100%);\n    left: 50%;\n    width: 24px;\n    height: 12px;\n    background: #cc3292;\n    border-radius: 50px 50px 0 0;\n  }\n}\n\n@keyframes flix {\n  0%,\n  60% {\n    transform: rotate(-10deg);\n  }\n  100%,\n  30%,\n  80% {\n    transform: rotate(5deg);\n  }\n}\n@keyframes rotate {\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingSpinnerWrapper": `LoadingSpinner_loadingSpinnerWrapper__MXmv9`,
	"loadingSpinner": `LoadingSpinner_loadingSpinner__hqQnS`,
	"flix": `LoadingSpinner_flix__LscrZ`,
	"rotate": `LoadingSpinner_rotate__FORGL`
};
export default ___CSS_LOADER_EXPORT___;
