// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactsInfo_contactsInfoWrapper__bzuQY {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.ContactsInfo_title__wPN1z {
  font-family: "Inter-Bold";
  font-size: 18px;
  letter-spacing: 1.8px;
}

.ContactsInfo_itemWrapper__fdry2 {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.ContactsInfo_itemTitle__GUn86 {
  font-family: "Inter-Semibold";
  letter-spacing: 1.6px;
}

.ContactsInfo_dataWrapper__i8AFO {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.ContactsInfo_dataItem__aTnRO {
  font-family: "Inter-Medium";
  letter-spacing: 0.8px;
}
.ContactsInfo_dataItem__aTnRO a {
  text-decoration: none;
  color: #000;
}`, "",{"version":3,"sources":["webpack://./src/entities/ContactsInfo/ui/ContactsInfo.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,yBAAA;EACA,eAAA;EACA,qBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,6BAAA;EACA,qBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AACF;;AAEA;EACE,2BAAA;EACA,qBAAA;AACF;AACE;EACE,qBAAA;EACA,WAAA;AACJ","sourcesContent":[".contactsInfoWrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.title {\n  font-family: \"Inter-Bold\";\n  font-size: 18px;\n  letter-spacing: 1.8px;\n}\n\n.itemWrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n\n.itemTitle {\n  font-family: \"Inter-Semibold\";\n  letter-spacing: 1.6px;\n}\n\n.dataWrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.dataItem {\n  font-family: \"Inter-Medium\";\n  letter-spacing: 0.8px;\n\n  a {\n    text-decoration: none;\n    color: #000;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contactsInfoWrapper": `ContactsInfo_contactsInfoWrapper__bzuQY`,
	"title": `ContactsInfo_title__wPN1z`,
	"itemWrapper": `ContactsInfo_itemWrapper__fdry2`,
	"itemTitle": `ContactsInfo_itemTitle__GUn86`,
	"dataWrapper": `ContactsInfo_dataWrapper__i8AFO`,
	"dataItem": `ContactsInfo_dataItem__aTnRO`
};
export default ___CSS_LOADER_EXPORT___;
