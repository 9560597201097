// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button_button__JHb7W {
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 10px;
  padding: 8px 32px;
  font-family: "Inter-Bold";
  font-size: 18px;
  letter-spacing: 1.8px;
  transition: all 0.5s;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
}
@media screen and (max-width: 800px) {
  .Button_button__JHb7W {
    padding: 8px 16px;
    font-family: "Inter-SemiBold";
    font-size: 16px;
    letter-spacing: 1.4px;
  }
}
.Button_button__JHb7W.Button_disabled__8gh5t {
  opacity: 0.5 !important;
  pointer-events: none !important;
}

.Button_primaryButton__Xi9Wg {
  border: 1px solid #fff;
  background-color: #cc3292;
  color: #fff;
}
.Button_primaryButton__Xi9Wg:hover {
  border: 1px solid #cc3292;
  background-color: #fff;
  color: #cc3292;
}

.Button_secondaryButton__MsjRv {
  border: 1px solid #cc3292;
  background-color: #fff;
  color: #cc3292;
}
.Button_secondaryButton__MsjRv:hover {
  border: 1px solid #fff;
  background-color: #cc3292;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/Button/Button.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EAAA,kBAAA;EACA,mBAAA;EACA,iBAAA;EACA,yBAAA;EACA,eAAA;EACA,qBAAA;EACA,oBAAA;EACA,yBAAA;UAAA,iBAAA;EACA,eAAA;AACF;AACE;EAXF;IAYI,iBAAA;IACA,6BAAA;IACA,eAAA;IACA,qBAAA;EAEF;AACF;AAAE;EACE,uBAAA;EACA,+BAAA;AAEJ;;AAEA;EACE,sBAAA;EACA,yBAAA;EACA,WAAA;AACF;AACE;EACE,yBAAA;EACA,sBAAA;EACA,cAAA;AACJ;;AAGA;EACE,yBAAA;EACA,sBAAA;EACA,cAAA;AAAF;AAEE;EACE,sBAAA;EACA,yBAAA;EACA,WAAA;AAAJ","sourcesContent":[".button {\n  width: fit-content;\n  border-radius: 10px;\n  padding: 8px 32px;\n  font-family: \"Inter-Bold\";\n  font-size: 18px;\n  letter-spacing: 1.8px;\n  transition: all 0.5s;\n  user-select: none;\n  cursor: pointer;\n\n  @media screen and (max-width: 800px) {\n    padding: 8px 16px;\n    font-family: \"Inter-SemiBold\";\n    font-size: 16px;\n    letter-spacing: 1.4px;\n  }\n\n  &.disabled {\n    opacity: 0.5 !important;\n    pointer-events: none !important;\n  }\n}\n\n.primaryButton {\n  border: 1px solid #fff;\n  background-color: #cc3292;\n  color: #fff;\n\n  &:hover {\n    border: 1px solid #cc3292;\n    background-color: #fff;\n    color: #cc3292;\n  }\n}\n\n.secondaryButton {\n  border: 1px solid #cc3292;\n  background-color: #fff;\n  color: #cc3292;\n\n  &:hover {\n    border: 1px solid #fff;\n    background-color: #cc3292;\n    color: #fff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Button_button__JHb7W`,
	"disabled": `Button_disabled__8gh5t`,
	"primaryButton": `Button_primaryButton__Xi9Wg`,
	"secondaryButton": `Button_secondaryButton__MsjRv`
};
export default ___CSS_LOADER_EXPORT___;
