// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SliderModal_modalWrapper__IDOfL {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.SliderModal_modalWrapper__IDOfL .SliderModal_title__qthBr {
  font-family: "Inter-SemiBold";
  letter-spacing: 1.8px;
  font-size: 14px;
  line-height: 150%;
  text-align: left;
}
.SliderModal_modalWrapper__IDOfL .SliderModal_contentWrapper__CFsHc {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 500px;
}
.SliderModal_modalWrapper__IDOfL .SliderModal_contentWrapper__CFsHc input {
  width: 100%;
  max-width: unset;
}`, "",{"version":3,"sources":["webpack://./src/features/SliderModal/ui/SliderModal.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;AACE;EACE,6BAAA;EACA,qBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;AACJ;AAEE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,YAAA;AAAJ;AAEI;EACE,WAAA;EACA,gBAAA;AAAN","sourcesContent":[".modalWrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n\n  .title {\n    font-family: \"Inter-SemiBold\";\n    letter-spacing: 1.8px;\n    font-size: 14px;\n    line-height: 150%;\n    text-align: left;\n  }\n\n  .contentWrapper {\n    display: flex;\n    flex-direction: column;\n    gap: 12px;\n    width: 500px;\n\n    input {\n      width: 100%;\n      max-width: unset;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalWrapper": `SliderModal_modalWrapper__IDOfL`,
	"title": `SliderModal_title__qthBr`,
	"contentWrapper": `SliderModal_contentWrapper__CFsHc`
};
export default ___CSS_LOADER_EXPORT___;
