// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextArea_textArea__t4bQg {
  display: flex;
  height: 193px;
  padding: 8px 16px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid #000;
  resize: none;
  transition: all 0.5s;
  outline: none;
}
.TextArea_textArea__t4bQg::placeholder {
  transition: all 0.5s;
}
.TextArea_textArea__t4bQg:focus {
  border: 1px solid #cc3292;
  color: #cc3292;
}
.TextArea_textArea__t4bQg:focus::placeholder {
  color: #cc3292;
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/TextArea/TextArea.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,iBAAA;EACA,uBAAA;EACA,SAAA;EACA,mBAAA;EACA,mBAAA;EACA,sBAAA;EACA,YAAA;EACA,oBAAA;EACA,aAAA;AACF;AACE;EACE,oBAAA;AACJ;AAEE;EACE,yBAAA;EACA,cAAA;AAAJ;AAEI;EACE,cAAA;AAAN","sourcesContent":[".textArea {\n  display: flex;\n  height: 193px;\n  padding: 8px 16px;\n  align-items: flex-start;\n  gap: 10px;\n  align-self: stretch;\n  border-radius: 10px;\n  border: 1px solid #000;\n  resize: none;\n  transition: all 0.5s;\n  outline: none;\n\n  &::placeholder {\n    transition: all 0.5s;\n  }\n\n  &:focus {\n    border: 1px solid #cc3292;\n    color: #cc3292;\n\n    &::placeholder {\n      color: #cc3292;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textArea": `TextArea_textArea__t4bQg`
};
export default ___CSS_LOADER_EXPORT___;
