// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App_appWrapper__1HIzR {
  width: 100vw;
  height: 100vh;
  height: 100dvh;
}

div,
p {
  font-family: "Inter-Regular";
  font-size: 14px;
  line-height: 150%;
  text-align: left;
}

h1 {
  font-family: "Inter-Medium";
  font-size: 18px;
  line-height: 175%;
}`, "",{"version":3,"sources":["webpack://./src/app/App/ui/App.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,cAAA;AACF;;AAEA;;EAEE,4BAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;AACF;;AAEA;EACE,2BAAA;EACA,eAAA;EACA,iBAAA;AACF","sourcesContent":[".appWrapper {\n  width: 100vw;\n  height: 100vh;\n  height: 100dvh;\n}\n\ndiv,\np {\n  font-family: \"Inter-Regular\";\n  font-size: 14px;\n  line-height: 150%;\n  text-align: left;\n}\n\nh1 {\n  font-family: \"Inter-Medium\";\n  font-size: 18px;\n  line-height: 175%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appWrapper": `App_appWrapper__1HIzR`
};
export default ___CSS_LOADER_EXPORT___;
