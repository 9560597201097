// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LeafletMap_mapWrapper__pi56l {
  width: 100%;
  height: auto;
  aspect-ratio: 4/2;
}`, "",{"version":3,"sources":["webpack://./src/widgets/LeafletMap/ui/LeafletMap.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,iBAAA;AACF","sourcesContent":[".mapWrapper {\n  width: 100%;\n  height: auto;\n  aspect-ratio: 4/2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mapWrapper": `LeafletMap_mapWrapper__pi56l`
};
export default ___CSS_LOADER_EXPORT___;
