// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("shared/assets/fonts/Inter-Black.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("shared/assets/fonts/Inter-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("shared/assets/fonts/Inter-ExtraBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("shared/assets/fonts/Inter-ExtraLight.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("shared/assets/fonts/Inter-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("shared/assets/fonts/Inter-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("shared/assets/fonts/Inter-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("shared/assets/fonts/Inter-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("shared/assets/fonts/Inter-Thin.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter-Regular";
}

html {
  scroll-behavior: smooth;
}

body {
  position: relative;
}

@font-face {
  font-family: "Inter-Black";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

@font-face {
  font-family: "Inter-Bold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

@font-face {
  font-family: "Inter-ExtraBold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}

@font-face {
  font-family: "Inter-ExtraLight";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}

@font-face {
  font-family: "Inter-Light";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
}

@font-face {
  font-family: "Inter-Medium";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_5___});
}

@font-face {
  font-family: "Inter-Regular";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___});
}

@font-face {
  font-family: "Inter-SemiBold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_7___});
}

@font-face {
  font-family: "Inter-Thin";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_8___});
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,4BAA4B;AAC9B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;EAC1B,4CAA+C;AACjD;;AAEA;EACE,yBAAyB;EACzB,4CAA8C;AAChD;;AAEA;EACE,8BAA8B;EAC9B,4CAAmD;AACrD;;AAEA;EACE,+BAA+B;EAC/B,4CAAoD;AACtD;;AAEA;EACE,0BAA0B;EAC1B,4CAA+C;AACjD;;AAEA;EACE,2BAA2B;EAC3B,4CAAgD;AAClD;;AAEA;EACE,4BAA4B;EAC5B,4CAAiD;AACnD;;AAEA;EACE,6BAA6B;EAC7B,4CAAkD;AACpD;;AAEA;EACE,yBAAyB;EACzB,4CAA8C;AAChD","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-family: \"Inter-Regular\";\n}\n\nhtml {\n  scroll-behavior: smooth;\n}\n\nbody {\n  position: relative;\n}\n\n@font-face {\n  font-family: \"Inter-Black\";\n  src: url('shared/assets/fonts/Inter-Black.ttf');\n}\n\n@font-face {\n  font-family: \"Inter-Bold\";\n  src: url('shared/assets/fonts/Inter-Bold.ttf');\n}\n\n@font-face {\n  font-family: \"Inter-ExtraBold\";\n  src: url('shared/assets/fonts/Inter-ExtraBold.ttf');\n}\n\n@font-face {\n  font-family: \"Inter-ExtraLight\";\n  src: url('shared/assets/fonts/Inter-ExtraLight.ttf');\n}\n\n@font-face {\n  font-family: \"Inter-Light\";\n  src: url('shared/assets/fonts/Inter-Light.ttf');\n}\n\n@font-face {\n  font-family: \"Inter-Medium\";\n  src: url('shared/assets/fonts/Inter-Medium.ttf');\n}\n\n@font-face {\n  font-family: \"Inter-Regular\";\n  src: url('shared/assets/fonts/Inter-Regular.ttf');\n}\n\n@font-face {\n  font-family: \"Inter-SemiBold\";\n  src: url('shared/assets/fonts/Inter-SemiBold.ttf');\n}\n\n@font-face {\n  font-family: \"Inter-Thin\";\n  src: url('shared/assets/fonts/Inter-Thin.ttf');\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
