export const ro = {
  STREET: "Chișinău, str. Alecu Russo 15",
  HOME: "Acasă",
  PRODUCTS: "Produse",
  ABOUT: "Despre noi",
  CONTACTS: "Contacte",
  NEW_OFFERS: "Oferte Noi",
  SPECIAL_OFFERS: "Oferte Speciale",
  SHORT_INFORMATION_TITLE:
    "Cărucioare pentru copii moderne și <br /> scaune auto în Moldova",
  SHORT_INFORMATION_DESCRIPTION:
    "Principiul principal al magazinului  nostru este să selectăm și să oferim părinților produse de înaltă calitate și verificate ! În fiecare zi studiem și selectăm sortimentul astfel încât să poți alege cel mai bun din fiecare segment de preț!",
  MORE: "Mai Multe",
  ALL_PRODUCTS: "Toate produsele",
  ABOUT_PAGE_DESCRIPTION:
    "Principiul principal al magazinului  nostru este să selectăm și să oferim părinților produse de înaltă calitate și verificate ! În fiecare zi studiem și selectăm sortimentul astfel încât să poți alege cel mai bun din fiecare segment de preț!",
  SEND_A_MESSAGE: "Trimite un mesaj",
  SEND: "Trimite",
  PHONE: "Telefon",
  ADDRESS: "Adresa",
  WORK_HOURS: "Orele de lucru",
  WORK_DAYS: "Ln - Sa  10:00 - 20:00",
  WEEK_DAYS: "Du  10:00 - 18:00",
  NAME: "Nume",
  EMAIL: "E-mail",
  TITLE_MESSAGE: "Titlul mesajului",
  MESSAGE: "Mesajul",
  FILTERS: "Filtre",
  SEARCH_PRODUCT: "Caută produs",
  FROM: "De la",
  TO: "Până la",
  PRICE: "Preț",
  APPLY: "Aplică",
  CATALOG: "Catalog",
  ADD_TO_CART_TO_SEE: "Adaugă în coș pentru a vedea produse",
  COMMAND: "Comandă",
  BACK_HOME: "Înapoi Acasă",
};
