// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.About_aboutWrapper__l2RB5 {
  margin: 48px 0;
  padding: 0 26px;
  display: flex;
  flex-direction: column;
  gap: 48px;
  min-height: calc(100vh - 404px);
}
@media screen and (min-width: 1316px) {
  .About_aboutWrapper__l2RB5 {
    max-width: 1260px;
    margin: 48px auto;
    padding: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/About/ui/About.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,eAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,+BAAA;AACF;AACE;EARF;IASI,iBAAA;IACA,iBAAA;IACA,UAAA;EAEF;AACF","sourcesContent":[".aboutWrapper {\n  margin: 48px 0;\n  padding: 0 26px;\n  display: flex;\n  flex-direction: column;\n  gap: 48px;\n  min-height: calc(100vh - 404px);\n\n  @media screen and (min-width: 1316px) {\n    max-width: 1260px;\n    margin: 48px auto;\n    padding: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aboutWrapper": `About_aboutWrapper__l2RB5`
};
export default ___CSS_LOADER_EXPORT___;
