// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotFound_contentWrapper__RLTpp {
  width: 100%;
  height: calc(100vh - 319px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.NotFound_contentWrapper__RLTpp a {
  color: #000;
}`, "",{"version":3,"sources":["webpack://./src/pages/NotFound/ui/NotFound.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,2BAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;AACE;EACE,WAAA;AACJ","sourcesContent":[".contentWrapper {\n  width: 100%;\n  height: calc(100vh - 319px);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  a {\n    color: #000;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentWrapper": `NotFound_contentWrapper__RLTpp`
};
export default ___CSS_LOADER_EXPORT___;
