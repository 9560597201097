// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddMobileModal_modalWrapper__gGdy7 {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.AddMobileModal_title__W6hUV {
  font-family: "Inter-Bold";
  font-size: 18px;
  letter-spacing: 1.8px;
}`, "",{"version":3,"sources":["webpack://./src/features/AddMobileModal/ui/AddMobileModal.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,yBAAA;EACA,eAAA;EACA,qBAAA;AACF","sourcesContent":[".modalWrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.title {\n  font-family: \"Inter-Bold\";\n  font-size: 18px;\n  letter-spacing: 1.8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalWrapper": `AddMobileModal_modalWrapper__gGdy7`,
	"title": `AddMobileModal_title__W6hUV`
};
export default ___CSS_LOADER_EXPORT___;
