// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Select_selectWrapper__bBgP1 {
  width: 100%;
  position: relative;
  -webkit-user-select: none;
          user-select: none;
}

.Select_value__VlUcI {
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  border: 1px solid #000;
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
}

.Select_placeholder__PRQWq {
  color: #7e7e7e;
}

.Select_optionsWrapper__Nmlcp {
  border: 1px solid #000;
  border-radius: 10px;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(calc(-100% - 8px));
  width: 100%;
  padding: 8px 16px;
  box-shadow: 8px 10px 15px 1px rgba(161, 161, 161, 0.75);
  -webkit-box-shadow: 8px 10px 15px 1px rgba(161, 161, 161, 0.75);
  -moz-box-shadow: 8px 10px 15px 1px rgba(161, 161, 161, 0.75);
  max-height: 300px;
  overflow-y: auto;
}

.Select_option__nbfrt {
  cursor: pointer;
  font-size: 14px;
  margin-top: 4px;
}
.Select_option__nbfrt:first-child {
  margin-top: 0;
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/Select/Select.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kBAAA;EACA,yBAAA;UAAA,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,iBAAA;EACA,sBAAA;EACA,mBAAA;EACA,eAAA;EACA,eAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,sBAAA;EACA,mBAAA;EACA,sBAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,wCAAA;EACA,WAAA;EACA,iBAAA;EACA,uDAAA;EACA,+DAAA;EACA,4DAAA;EACA,iBAAA;EACA,gBAAA;AACF;;AAEA;EACE,eAAA;EACA,eAAA;EACA,eAAA;AACF;AACE;EACE,aAAA;AACJ","sourcesContent":[".selectWrapper {\n  width: 100%;\n  position: relative;\n  user-select: none;\n}\n\n.value {\n  display: flex;\n  justify-content: space-between;\n  padding: 8px 16px;\n  border: 1px solid #000;\n  border-radius: 10px;\n  cursor: pointer;\n  font-size: 14px;\n}\n\n.placeholder {\n  color: #7e7e7e;\n}\n\n.optionsWrapper {\n  border: 1px solid #000;\n  border-radius: 10px;\n  background-color: #fff;\n  position: absolute;\n  top: 0;\n  left: 0;\n  transform: translateY(calc(-100% - 8px));\n  width: 100%;\n  padding: 8px 16px;\n  box-shadow: 8px 10px 15px 1px rgba(161, 161, 161, 0.75);\n  -webkit-box-shadow: 8px 10px 15px 1px rgba(161, 161, 161, 0.75);\n  -moz-box-shadow: 8px 10px 15px 1px rgba(161, 161, 161, 0.75);\n  max-height: 300px;\n  overflow-y: auto;\n}\n\n.option {\n  cursor: pointer;\n  font-size: 14px;\n  margin-top: 4px;\n\n  &:first-child {\n    margin-top: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectWrapper": `Select_selectWrapper__bBgP1`,
	"value": `Select_value__VlUcI`,
	"placeholder": `Select_placeholder__PRQWq`,
	"optionsWrapper": `Select_optionsWrapper__Nmlcp`,
	"option": `Select_option__nbfrt`
};
export default ___CSS_LOADER_EXPORT___;
