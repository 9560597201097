// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StickyInfo_stikyInfoWrapper__fFcx\\+ {
  background-color: #e8e8e8;
  padding: 13px 26px;
}

.StickyInfo_contentWrapper__DRUQS {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1260px;
  margin: 0 auto;
}
@media screen and (max-width: 800px) {
  .StickyInfo_contentWrapper__DRUQS {
    flex-direction: column;
    gap: 16px;
  }
}

.StickyInfo_itemWrapper__KgszW {
  display: flex;
  align-items: center;
  gap: 10px;
}
.StickyInfo_itemWrapper__KgszW svg {
  width: auto;
  height: 24px;
  fill: #000;
}
.StickyInfo_itemWrapper__KgszW p {
  color: #000;
  font-family: "Inter-SemiBold";
  letter-spacing: 0.8px;
}
.StickyInfo_itemWrapper__KgszW a {
  text-decoration: none;
  font-family: "Inter-SemiBold";
  font-size: 14px;
  letter-spacing: 0.8px;
  color: #000;
}

@media screen and (max-width: 800px) {
  .StickyInfo_switcher__wFPHk {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/entities/StickyInfo/ui/StickyInfo.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,iBAAA;EACA,cAAA;AACF;AACE;EAPF;IAQI,sBAAA;IACA,SAAA;EAEF;AACF;;AACA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAEF;AAAE;EACE,WAAA;EACA,YAAA;EACA,UAAA;AAEJ;AACE;EACE,WAAA;EACA,6BAAA;EACA,qBAAA;AACJ;AAEE;EACE,qBAAA;EACA,6BAAA;EACA,eAAA;EACA,qBAAA;EACA,WAAA;AAAJ;;AAKE;EADF;IAEI,aAAA;EADF;AACF","sourcesContent":[".stikyInfoWrapper {\n  background-color: #e8e8e8;\n  padding: 13px 26px;\n}\n\n.contentWrapper {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  max-width: 1260px;\n  margin: 0 auto;\n\n  @media screen and (max-width: 800px) {\n    flex-direction: column;\n    gap: 16px;\n  }\n}\n\n.itemWrapper {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n\n  svg {\n    width: auto;\n    height: 24px;\n    fill: #000;\n  }\n\n  p {\n    color: #000;\n    font-family: \"Inter-SemiBold\";\n    letter-spacing: 0.8px;\n  }\n\n  a {\n    text-decoration: none;\n    font-family: \"Inter-SemiBold\";\n    font-size: 14px;\n    letter-spacing: 0.8px;\n    color: #000;\n  }\n}\n\n.switcher {\n  @media screen and (max-width: 800px) {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stikyInfoWrapper": `StickyInfo_stikyInfoWrapper__fFcx+`,
	"contentWrapper": `StickyInfo_contentWrapper__DRUQS`,
	"itemWrapper": `StickyInfo_itemWrapper__KgszW`,
	"switcher": `StickyInfo_switcher__wFPHk`
};
export default ___CSS_LOADER_EXPORT___;
