export const ru = {
  STREET: "Кишинев, ул. Алеку Руссо, 15",
  HOME: "Домой",
  PRODUCTS: "Продукты",
  ABOUT: "О нас",
  CONTACTS: "Контакты",
  NEW_OFFERS: "Новые Предложения",
  SPECIAL_OFFERS: "Специальные Предложения",
  SHORT_INFORMATION_TITLE:
    "Современные детские коляски и <br /> автокресла в Молдове",
  SHORT_INFORMATION_DESCRIPTION:
    "Мы официальные дилеры полной линейки продукции, <br /> представляя бренды европейского качества, такие как: <br /> <i>Mutsy, Cybex, Anex, Muuvo, Verdi, Tutis, EasyGO и другие.</i>",
  MORE: "Больше",
  ALL_PRODUCTS: "Все Товары",
  ABOUT_PAGE_DESCRIPTION:
    "Мы официальные дилеры полной линейки продукции, <br /> представляя бренды европейского качества, такие как:<br /> <i>Mutsy, Cybex, Anex, Muuvo, Verdi, Tutis, EasyGO и другие.</i> <br /><br />Кроме того, у нас есть высококвалифицированная команда,<br />которая поможет вам выбрать лучший товар для вашего ребенка.",
  SEND_A_MESSAGE: "Отправить сообщение",
  SEND: "Отправить",
  PHONE: "Телефон",
  ADDRESS: "Адрес",
  WORK_HOURS: "Рабочее время",
  WORK_DAYS: "Пн - Сб 10:00 - 20:00",
  WEEK_DAYS: "Вт 10:00 - 18:00",
  NAME: "Имя",
  EMAIL: "Электронная почта",
  TITLE_MESSAGE: "Название сообщения",
  MESSAGE: "Сообщение",
  FILTERS: "Фильтры",
  SEARCH_PRODUCT: "Поиск продукта",
  FROM: "От",
  TO: "До",
  PRICE: "Цена",
  APPLY: "Применять",
  CATALOG: "Каталог",
  ADD_TO_CART_TO_SEE: "Добавьте в корзину, чтобы просмотреть товары",
  COMMAND: "Команда",
  BACK_HOME: "Домой",
  CĂRUCIOARE_2_IN_1_DESCRIPTION:
    '<h1 style="text-align: center;">Коляски для прогулки 2 в 1 в Кишиневе</h1>\n' +
    "Заботливые расчётливые родители, не подверженные суевериям еще задолго до рождения ребенка стараются по максимуму приготовиться к этому замечательному событию. Одним из обязательных атрибутов младенчества является коляска. Но лучше прислушаться к мнению квалифицированного консультанта и сделать правильный выбор.\n" +
    "\n" +
    "На данный момент наибольшей популярностью пользуются <strong>детские коляски 2 в 1 для прогулки в Молдове</strong>. Они удобны и универсальны, так как коляска такого типа заменит вам две полноценные модели: с люлькой и блоком для прогулок, в котором ребенок может уже сидеть. В условиях городской квартиры она позволит вам существенно сэкономить место. Это также свойственно <strong>коляскам для прогулок</strong>, даже если они идут <strong>для близнецов</a></strong>. Одним из ярких примеров колясок обоих видов от знаменитых производителей являются Mutsy, Anex, Muuvo и другие.\n" +
    "\n" +
    "Разобравшись с самим понятием колясок-трансформеров, следующим фактором выбора является материал. Как правило, современные популярные бренды изготавливают легкие прочные металлические рамы, которые служат практически вечно, если соблюдать правила эксплуатации и не перегружать коляску. Очень важно уточнить, перед тем как <strong>заказать коляски 2 в 1 в Кишиневе</strong>, на какой вес она рассчитана. От этого будет зависеть, как она вам будет служить. В среднем она вам понадобиться до трех лет, пока ребенок не пересядет на велосипед или не станет на самокат.\n" +
    "\n" +
    "Если вы решили посмотреть и <strong>купить детские коляски 2 в 1 в Молдове</strong>, обязательно обсудите с продавцом вопрос маневренности коляски. Во время длительных прогулок придется преодолеть ни один километр, соответственно управляемость очень важна. Также покупая коляски, обратите внимание на амортизацию колес. Малышу в люльке или прогулочном блоке будет значительно комфортно, если он будет равномерно покачиваться, а не ощущать каждую ямку и камешек на дороге.\n" +
    '<p style="text-align: center;"><strong>Oanabebe.md - коляски, автокресла и кроватки для детей в Молдове.</strong></p>',
  CĂRUCIOARE_3_IN_1_DESCRIPTION:
    '<h1 style="text-align: center;">Коляски 3 в 1 для прогулки в Молдове.</h1>\n' +
    "Одна из первых и самых важных покупок для вашего ребенка будет его детская коляска. Ее выбор полностью зависит от многих факторов. В их список входят бюджет семьи, функционал самой коляски и ее возможностей, но даже образ жизни родителей может сыграть немаловажную роль. Это влияет какую придется взять - <strong> коляску 2 в 1</strong> или <strong>коляску для близнецов</strong></a>, не забыв при этом про <strong>аксессуары для колясок</strong></a>.\n" +
    "\n" +
    "Самыми универсальными по сей день считаются <strong>детские коляски 3 в 1 для прогулки в Кишиневе</strong>. Это и неудивительно, так как заплатив один раз, вы получите в дополнение переносную люльку со сменным прогулочным блоком и удобным автокреслом для новорожденных. Этой коляской вы сможете пользоваться от самого рождения малыша и до трех, а может даже до четырех лет, пока ваше чадо не пересядет на велосипед или самокат. Хорошими примерами такого типа колясок от мировых брендов являются Verdi Orion, Verdi Mirage, Easy GO Soul и другие.\n" +
    "\n" +
    "Вторым важным правилом при выборе <strong>детской коляски 3 в 1 в Молдове</strong> являются ее колеса. Обычно предпочтения отдаются резиновым колесам. Они лучше смягчают удары от столкновений во время прогулок, но в отличие от полиуретановых, больше подвержены повреждениям от острых предметов. В целом, колеса должны удобно и быстро сниматься, что очень важно при транспортировке самой коляски. Обратить внимание надо и на наличие амортизаторов и на тип тормозов. Если маршрут ваших прогулок будет проходить по наклонным дорожкам, то наличие ручного тормоза значительно упростит вам прогулки.\n" +
    "\n" +
    "Все перечисленное выше создает удобство и комфорт для ребенка. Но где и как <strong>купить коляски 3 в 1 в Кишиневе</strong>, чтоб устроила цена, и качество, и модель понравилась. С таким вопросом лучше обратится к нам в специализированный детский магазин, где наши консультанты помогут вам дельными советами. Все коляски, которые продаются и которые вы можете заказать на нашем сайте, проверяются и тестируются перед упаковкой и продажей. Мы полностью уверенны в наших поставщиках, но считаем необходимым лично перепроверить и удостовериться в исправности каждой модели. Поэтому наши клиенты получают только качественный и надежный товар от ведущих известных торговых марок.\n" +
    '<p style="text-align: center;"><strong>Oanabebe.md - коляски, автокресла и кроватки для детей в Молдове.</strong></p>',
  CĂRUCIOARE_DE_PLIMBARE_DESCRIPTION:
    '<h1 style="text-align: center;">Прогулочные коляски для детей в Кишиневе.</h1>\n' +
    "На сегодняшний день вы сможете выбрать коляски любого типа от разных торговых марок по предпочтениям вашего ребенка. Но перед покупкой необходимо понимать, какая все-таки вам подойдет. При выборе также можно уточнить - нужны ли вам <strong>аксессуары для детских колясок</strong></a> или когда вам понадобится <strong>детский велосипед</strong></a>.\n" +
    "\n" +
    "<strong>Прогулочные коляски для малышей в Молдове</strong> в виде коляски-книжки представляет собой демисезонный вариант. Сами они тяжелые, так как их вес составляет от 8 до 11 килограммов. Они хороши в любую погоду, так как у них спальное место и колёса больше чем у иного вида. Когда становится холодно и малыша нужно утеплить, то данный вид коляски позволяет быстро одеть его в комбинезон или закутать в плед. Ну а если ребенок крупный, то это нужный вариант, на котором Вам следует остановиться. Большие колеса отлично справляются с преодолением сложных участков, они не застрянут в грязи или в снегу.\n" +
    "\n" +
    "<strong>Прогулочные коляски в Кишиневе</strong> второго вида, коляски-трости, идеальны для летних прогулок и для более долгих путешествий, когда на улице жарко. Важным преимуществом тростей является их легкий вес — от 4 до 7,5 килограмм, а также простой и лёгкий способ складывания. Любая мама справится с этим заданием за несколько секунд, при этом, маленькие колёса не создают дискомфорта. Все это очень удобно при поездках в общественном транспорте. Одним из ярких примеров колясок обоих видов от знаменитых производителей являются 4BABY Rapid, Easy GO Virage, Easy GO Optimo и другие.\n" +
    "\n" +
    "Выбирая <strong>детские прогулочные коляски в Молдове</strong>, важно самому разобраться в ее предназначении. Главной вопрос в том - нужна ли вам легкая и компактная, а может именно такая, с которой вы выйдете в любую погоду и по любым дорогам. Но, если вы решили полностью отказаться от универсальной, которой являются <strong>детские коляски 3 в 1</strong></a>, то лучше рассмотреть демисезонные коляски. Однако, если есть возможность чередовать прогулочную и универсальную, то наилучшим выбором будет коляска-трость.\n" +
    "\n" +
    "Какой бы из вариантов вы бы ни выбрали, можете быть уверенны, что в нашем магазине вы найдете различные варианты <strong>колясок для прогулок в Кишиневе</strong>. Сравнивая цены в других магазинах, вы обязательно вернетесь к нам. Благодаря советам наших опытных консультантов вы сможете подобрать подходящий вариант для себя и своего малыша для ежедневных веселых прогулок на свежем воздухе. Немаловажным будет то, что в зависимости от модели, коляски могут комплектоваться дождевиком и/или москитной сеткой, но вам нужно уточнять перед покупкой у менеджеров.\n" +
    '<p style="text-align: center;"><strong>Oanabebe.md - коляски, автокресла и кроватки для детей в Молдове.</strong></p>',
  CĂRUCIOARE_PENTRU_GEMENI_DESCRIPTION:
    '<h1 style="text-align: center;">Коляски для близнецов в Молдове.</h1>\n' +
    "Для каждой семьи появление ребенка это долгожданное знаменательное событие, это есть счастье для родителей. А появление сразу двоих малышей – это счастье в двойном размере, однако с таким двойным счастьем прибавляется больше хлопот и двойная ответственность. Теперь потребуется в два раза больше места не только дома, но и в коляске, даже если она <strong>для прогулок</strong></a>. Хорошо, что на сегодняшний день в огромном ассортименте представлены <strong>детские коляски для близнецов в Кишиневе</strong>. Стоит еще уточнить, что прогуляться сразу с двумя без коляски не представляется возможным.\n" +
    "\n" +
    "Их двое и каждый из них индивидуален по своему, поэтому к выбору такой коляски следует подходить очень ответственно. Самое основное, чем отличаются коляски для близнецов, это расположение лежачих или сидячих блоков. Они могут быть двух видов, а именно располагаться один за одним, напоминая паровозик, а также рядом, когда два малыша находятся бок о бок.\n" +
    "\n" +
    "Первый вид <strong>детских колясок для близнецов в Молдове</strong> является достаточно длинной конструкцией, но зато узкой. В ней малыши могут располагаться как на одном, таки на разных уровнях. В некоторых моделях можно развернуть  их лицом друг к другу, но в большинстве вариантов, одному из детишек придется ограничить зону просмотра. Однако, когда два ребенка сидят рядом, то у такой коляски длина соответствует обычной, но ширина в два раза больше. Стоит учитывать, что такие коляски войдут не в каждый подъезд и поместятся не в каждый лифт.\n" +
    "\n" +
    "Безусловно, каждый из вариантов найдет своих сторонников. В конструкции данных колясок применяются легкие сплавы металлов и прочный пластик, устойчивый к низким и высоким температурам. Позже будет труднее приобрести <strong>велосипеды для детей</strong></a> или <strong>детские самокаты</strong></a> так как каждый захочет иметь тот, который ему больше подойдет.\n" +
    "\n" +
    "Мы предлагаем своим клиентам различные <strong>коляски для близнецов в Кишиневе</strong> через интернет-магазин с удобным каталогом. Абсолютно все коляски такого типа имеют гарантию качества. Если у вас возникнут вопросы, то наш квалифицированный персонал с удовольствие даст на них исчерпывающие ответы. Мы сотрудничаем только с проверенными брендами, которые годами зарабатывали репутацию, разрабатывая новые модели. <strong>Купить коляски для близнецов в Молдове</strong> можно в любое время, оставив заявку на сайте или позвонив по одному из указанных номеров.\n" +
    '<p style="text-align: center;"><strong>Oanabebe.md - коляски, автокресла и кроватки для детей в Молдове.</strong></p>',
  ACCESORII_PENTRU_SCAUNE_AUTO_DESCRIPTION:
    '<h1 style="text-align: center;">Аксессуары для автокресла в Кишиневе.</h1>\n' +
    "При появлении малыша в доме появляются разные приятные хлопоты, приобретения, в том числе покупка различных устройств и аксессуаров для автокресла. В современном мире, когда практически ежедневно требуется передвижение, то без автомобиля не обойтись, как и без <strong>детского автокресла</strong></a> ибо безопасность вашего ребенка прежде всего должна стоять на первом месте. В некоторых странах могут вас оштрафовать за то, что его у вас нет!\n" +
    "\n" +
    "Автокресло отвечает за безопасность малыша в дороге, а всевозможные <strong>аксессуары для детского автокресла в Молдове</strong> создают комфорт. Приобретая автомобильное кресло, Вам понадобятся что-то дополнительное в зависимости от модели и марки транспортного средства, плюс различные потребности и особенности ребенка, так как в отличии от детских прогулочных колясок, большинство кресел не укомплектованы.\n" +
    "\n" +
    "<strong>Аксессуары для детского автокресла в Кишиневе</strong> нужно приобретать только у надежного продавца, поэтому наши консультанты дадут исчерпывающие ответы на все Ваши вопросы. Ведь нужно быть уверенным в том, хорошо ли кресло устанавливается, есть ли накладка на ремень  и не создаете ли вы дополнительное давление на тело малыша.\n" +
    "\n" +
    "Ассортимент дополнительных деталей у нас невероятно велик и среди них Вы найдете различные адаптеры для надежной фиксации кресла на сидении автомобиля, комплектующие запчасти и зеркала, различные шторки и уплотнители на ремни. У нас даже есть чехлы и коврики для прекрасной организованности пространства в машине.\n" +
    "\n" +
    "Поэтому <strong>купить аксессуары для автокресла в Молдове</strong> можно у нас, так как мы доставляем их быстро и принимаем оплату удобным для Вас способом. Ведь поработав однажды с нашим магазином, Вы снова и снова будете обращается к нам, покупая необходимые вещи самому дорогому человеку на свете – Вашему ребенку.\n" +
    '<p style="text-align: center;"><strong>Oanabebe.md - коляски, автокресла и кроватки для детей в Молдове.</strong></p>\n',
  CARUSELE_MUZICALE_DESCRIPTION:
    '<h1 style="text-align: center;">Музыкальные карусели в Молдове.</h1>\n' +
    "Хотите порадовать своего малыша, подарить ему сладкий сон и спокойствие, тогда обратите внимание в необходимости приобрести <strong>музыкальные карусели для детей в Кишиневе</strong>. Они в первую очередь являются необходимым кроватным аксессуаром для вашего чада. После этого, ребенок не только будет радовать взор родителей, но и наполнять счастьем сердце молодой матери, что обязательно понравится крохе.\n" +
    "\n" +
    "Особой популярностью пользуются <strong>музыкальные карусели для кроваток в Молдове</strong> со встроенными мелодиями. Также, детские педиатры приводят много положительных доводов в пользу приобретения прикроватного автомобиля. Во время вращения карусели, Ваш малыш концентрирует внимание и его мозг заставляет работать визуальный аппарат в усиленном режиме. Таким образом, ребенок быстрее начинает распознавать цвета, день и ночь, что в итоге уменьшает для него список окружающих факторов раздражения.\n" +
    "\n" +
    '<strong>Музыкальные карусели для кроваток в Кишиневе</strong> с регулированием уровня звука отлично подходят для всех, для малышей любящих по громче и для нежных крошек предпочитающих медленную приятную мелодию, убаюкивающую за пару минут. Посему, в нашем интернет магазине в ассортименте присутствуют разные по дизайну и функциональности музыкальные карусели. Мы представляем только высококачественный товар, безопасный для деток и родителей. В этот список также входят <strong>детские матрасы</a></strong>, <a href="http://office8o.beget.tech/ru/categorie-produs/camera-copilului/saltele-de-joaca/"><strong>матрасы для детских игр</strong></a> и <strong>детские качели</strong></a>.\n' +
    "\n" +
    "<strong>Купить музыкальные карусели для детей в Молдове</strong> просто, если Вы позвоните нашему менеджеру, если вдруг не можете сами определить, какими техническими качествами отличается одна модель от другой. Вы получите профессиональную консультацию и отбросите все свои сомнения. Запомните, что главное наше преимущество – это то, что Вы можете заказать все детские товары онлайн по выгодным ценам. Порадуйте своего ребенка, наслаждаясь каждой минутой проведенной с ним, что подарит ему хорошее настроение на каждый день!\n" +
    '<p style="text-align: center;"><strong>Oanabebe.md - коляски, автокресла и кроватки для детей в Молдове.</strong></p>',
  PATUȚURI_DESCRIPTION:
    '<h1 style="text-align: center;">Детские матрасы в Кишиневе.</h1>\n' +
    "В первую очередь, после приобретения кроваток, родителям  необходимо выбрать <strong>качественные матрасы для малышей в Молдове</strong>. Самое главное требование к нему – это умеренная жесткость. Спать новорожденным на мягких матрасах нежелательно, посему выбирают только жесткие матрасы. Это объясняется тем, что с самого рождения ребенок все свое время проводит в горизонтальном положении, а именно – на руках у матери, в коляске, в кроватке. И в это же время формируется костный скелет, осанка, поэтому матрас выполняет не только поддерживающую, но и формирующую функции. После создания основного скелета вы смело можете подобрать малышу <strong>детский манеж</strong></a> или <strong>матрас для игр</strong></a>.\n" +
    "\n" +
    "<strong>Матрасы для детей в Кишиневе</strong> изготавливаются только из натуральных и безопасных материалов, как и <strong>детское постельное белье</strong></a>. Забудьте о старых ватных матрасах, так как они не только неудобные, но и пользы точно не принесут. Современные модели изготавливаются в основном из хлопка, а вот наполнители могут быть разными. Преимущественно такие матрасы продаются с наполнителями из кокосовой койры, конского волоса, гречневой лузги или льна. Эти материалы могут быть самостоятельными или комбинироваться, однако реже в качестве наполнителя используется полиуретан или пеноматериалы.\n" +
    "\n" +
    "<strong>Детский матрас в Молдове</strong> формируется из послойно уложенных компонентов таким образом, чтобы подобрать необходимую степень жесткости для малыша. Для того, чтобы он служил долго, необходимо позаботиться о защите. Прежде всего, матрас нужно оберегать от попадания жидкостей, благодаря чему родители покупают специальные надматрасники, которые не пропускают влагу и при этом не препятствуют циркуляции воздуха. С таким натуральным матрасом вашему ребенку гарантирован крепкий комфортный сон на ровной поверхности.\n" +
    "\n" +
    "Современные матрасы прекрасно пропускают воздух, создавая особый микроклимат в кроватке малыша, так чтобы он не замерз и не потел по ночам. А если вы хотите <strong>купить детские матрасы в Кишиневе</strong> действительно высокого качества и с ортопедическими свойствами, то смело можете обращаться к нам. Не стоит экономить на таком важном предмете, так как экономия на матрасе – это прямая экономия на здоровье ребенка. Всегда уточняйте у продавца - прошел ли матрас клинические испытания, насколько гипоаллергенен его материал и отвечает ли он санитарно гигиеническим нормам. Наш магазин предоставляет возможность <strong>заказать матрасы для детей в Молдове</strong> по доступной цене и хорошего качества.\n" +
    '<p style="text-align: center;"><strong>Oanabebe.md - коляски, автокресла и кроватки для детей в Молдове.</strong></p>',
  LEAGĂNE_ȘI_BALANSOARE_DESCRIPTION:
    '<h1 style="text-align: center;">Детские качели в Кишиневе.</h1>\n' +
    "Современный мир несет с собой не только новые технологии для улучшения качества жизни, но и увеличивает скорость ее движения. Поэтому <strong>детские качалки для ребенка в Молдове</strong> стали постоянными помощниками для современных родителей, как и <strong>детские кроватки</strong></a>. Такой аксессуар должен быть простым, но получаемый от него эффект выше всяких похвал. Каждый малыш, находясь в нем, засыпает быстро, что позволит молодой маме отдохнуть на пару часов, а затем покормить его, используя <strong>детский стульчик для кормления</strong></a>. Таким образом, родители смогут заняться своими делами, а ребенок будет находиться рядом и мило спать.\n" +
    "\n" +
    "<strong>Детские шезлонги в Кишиневе</strong> доступны в разных модификациях, с разными углами качания и даже со встроенной музыкой. Но покупать нужно у проверенного поставщика, дабы быть уверенными, что данные качели произведены их экологически чистых материалов, которые не запрещены для детей. Также, у большинства моделей в комплектацию входят погремушки, свисающие сверху, ортопедические матрасики или <strong>матрасы для игр</strong></a>, что так же способствует убаюкиванию. Иной ряд моделей идет с регулировкой спинки, которую можно зафиксировать нужное положение для кормления малыша.\n" +
    "\n" +
    "В нашем ассортименте представлены <strong>детские качели в Молдове</strong> с разными ритмами качания, что непосредственно может подойти для всех типов детей. Но нужно принимать во внимание вопрос о том, удобен ли детский шезлонг или качалка для путешествий и транспортировки, в случае небольших прогулок молодой семьи. Благодаря многолетнему опыту мы вас уверяем что, после покупки на нашем сайте, Вы останетесь не только довольны, но и дадите нам положительный отзыв. Если вы хотите <strong>купить детские качалки в Кишиневе</strong>, то сделать вам это не составит труда. Позвоните нашим менеджерам и они проконсультируют по любому интересующему вас вопросу.\n" +
    '<p style="text-align: center;"><strong>Oanabebe.md - коляски, автокресла и кроватки для детей в Молдове.</strong></p>',
  MOBILIER_DESCRIPTION:
    '<h1 style="text-align: center;">Детская мебель в Молдове.</h1>\n' +
    "Рождение ребенка в каждой семье является праздником и нескончаемое счастье для родителей. Но это также требует ответственности. Посему, каждый родитель хочет создать уют и максимальный комфорт для своего малыша в его детской комнате. Однако, остро может встать вопрос о том, где же можно <strong>купить мебель для детей в Кишиневе.</strong>\n" +
    "\n" +
    "Для детской комнаты мебель должна изготавливаться из экологически чистых материалов, не иметь аллергенов в своей основе и не раздражать обоняние маленького ребенка. Поэтому <strong>мебель для детей в Молдове</strong> является таким немаловажным атрибутом жизни для самого ценного в вашей жизни. Задавая себе вопрос о том, какая должна быть детская мебель, нужно внимательно присмотреться в комнату малыша и решить, что вам подходит.\n" +
    "\n" +
    "В основном, все родителя покупают <strong>детский комод в Кишиневе,</strong> так как он всегда являлся частью общего гардероба, находясь рядом с <strong>детской кроваткой</strong></a> или <strong>детской качалкой</strong></a>. В нем вы сможете хранить <strong>средства по уходу за ребенком</strong></a> и постельное белье для детей</strong></a>. Они должны иметь соответствующие особенности и предназначения для будущего развития малыша. Посему, пожелания родителей обязательны, так как они учитываются в первую очередь.\n" +
    "\n" +
    "Делая выбор <strong>комода для детей в Молдове</strong>, обратите внимание на то, что материал, например, ДСП должен быть только из натурального дерева. Вы также должны интересоваться толщиной и главное составом клея используемого в производстве, чтобы это не коем образом не повлияло на обоняние вашего ребенка. Обращайте внимание и на фурнитуру, которая обязана иметь надежность и в тоже время быть безопасной, чтобы малыш не травмировал себя.\n" +
    "\n" +
    "<strong>Детская мебель для ребенка в Кишиневе</strong> представлена в широком ассортименте, но мы советуем делать выбор в сторону мебели пастельного цвета, так как большинство деток очень резко могут воспринимать яркую цветовую гамму. Поэтому необходимо приобретать качественную мебель для детской комнаты у людей, которым вы доверяете. Наш интернет магазин предлагает не только огромный ассортимент, но и предоставляет гарантию качества, сертификаты экологичности от производителей детской мебели. Мы уверены, что вы будете спокойны, а ваши дети будут в безопасности.\n" +
    '<p style="text-align: center;"><strong>Oanabebe.md - коляски, автокресла и кроватки для детей в Молдове.</strong></p>',
  SCAUNE_DE_MASA_DESCRIPTION:
    '<h1 style="text-align: center;">Детские стульчики для кормления ребенка в Кишиневе.</h1>\n' +
    "Никто не в силах идти против природы и зачастую, к возрасту от шести месяцев, ваш малыш уже самостоятельно умеет сидеть, учитывая скорость современной жизни. На данном этапе его развития это означает то, что пора родителям задуматься о приобретении <strong>стульчика для кормления в Молдове</strong>. Существует огромное разнообразие товаров для самых маленьких и поэтому детские стульчики пользуются огромнейшим спросом и по сей день, как и <strong>детское постельное белье</strong></a>, которое находится в ящиках <strong>детской мебели</strong></a>.\n" +
    "\n" +
    "<strong>Стульчики для кормления ребенка в Кишиневе</strong> необходимы на определенный период, но перед покупкой нужно учесть много разных факторов. Они должны быть созданы по стандартам и с использованием различных материалов. Есть модельные ряды из дерева, пластика или из комбинации нескольких материалов. Обилие цветовой гаммы сможет подойти по вкусу даже для самых требовательных родителей.\n" +
    "\n" +
    "<strong>Детские стульчики кормления в Молдове</strong> продолжают пользоваться исключительной популярностью. В их список также входят стульчики трансформеры. Они имеют небольшие габариты, занимают мало места и в любое время их можно сложить, поставив в укромное место. Такой стульчик имеет несколько положений спинки, так как он регулируется по высоте. Существуют уже такие модели, способные трансформироваться даже в небольшие кроватки, куда можно положить <strong>детский матрас</strong></a>.\n" +
    "\n" +
    "<strong>Детские стульчики кормления в Кишиневе</strong> находятся как в нашем магазине, так и на нашем сайте. Каждый родитель сам сможет выбрать модель по доступной цене, затем ознакомится с техническими характеристиками и потом позвонить по контактному телефону, получив консультации от наших профессиональных продавцов. У нас всегда в наличии столики для девочек  и для мальчиков. Возможны и варианты заказа двойных столиков для близнецов.\n" +
    "\n" +
    "<strong>Купить стульчики кормления для ребенка в Молдове</strong> сейчас проще простого. Для этого вам нужно всего лишь оформить онлайн заявку на нашем сайте. Будьте уверены, что в ближайшее время наши менеджеры перезвонят вам, оформят доставку выбранного товара и примут его оплату любым удобным для вас способом.\n" +
    '<p style="text-align: center;"><strong>Oanabebe.md - коляски, автокресла и кроватки для детей в Молдове.</strong></p>',
};
